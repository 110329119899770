import React from "react";

import "./Ad.css";

export const Ad = () => {
  return (
    <div className="adWrap">
      <h3 className="adText">Booth Space Available! CALL NOW!</h3>
    </div>
  );
};
