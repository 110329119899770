import React from "react";

import "./Footer.css";

export const Footer = () => {
  return (
    <div className="footerWrap">
      <h3>5957 N Michigan Rd. | Indianapolis, IN 46228 | (317) 259-4390</h3>
      <p>Powered by HTech</p>
    </div>
  );
};
